import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Navbar from '../components/navbar'

const TheLab = () => {
    return (
        <Layout>
            <SEO title="The Lab" />

            <Navbar />

            <section className="hero is-fullheight">
                <div className="hero-head imaginarium-header">
                    <div className="container">
                        <h1 className="title pt-3 pb-3 imaginarium-title is-size-1 is-size-3-mobile has-text-centered">
                            THE LAB
                        </h1>
                    </div>
                </div>
                <div className="hero-body" style={{flexDirection: 'column'}}>
                        <div className="column is-two-thirds has-text-centered">
                            <iframe src="https://app.milanote.com/1Kg3ql1Eg9dg6W?p=hFPj7Engs57" 
                                    width="100%" height="600" frameBorder="0" className="past-lives-milanote mb-3" title="A-KRA dreams on Milanote">
                            </iframe>
                            <p>Who are we if not our dreams, and the dreams of those who love us?</p>
                        </div>

                        <div className="column content is-half is-size-7 has-text-justified has-text-left-mobile">
                            <p><i>A-KRA Studio’s dream?</i></p>

                            <h2 className="is-size-2">THE LAB</h2>

                            <p>
                                Above is a creative/research visualisation of an artist-run gallery + cultural consultancy 
                                space in Accra, Ghana. For about a year and a half, A-KRA has been operating out of host 
                                institutions and on the world wide web. We are immensely grateful so far to The Gold Coast 
                                Report, Ano Institute of Arts and Knowledge, Terra Alta, The Library of Africa and The African 
                                Diaspora, and Autograph ABP for their support in activating versions of our work.
                            </p>

                            <p>
                                In our sketched design, you can spot clues of our aspirations. The LAB would be a hub for collaboration, 
                                research workshops, zine making, participatory art exhibitions, imaginariums, and edutainment programming 
                                for young dreamers.
                            </p>

                            <p>
                                Fundraising efforts to rent a space for a short-term prototype/pop-up version will begin 
                                later this year, and if you’d like to help with bringing this vision into our dimension, 
                                please email <a href="mailto:yaa@akra.studio">yaa@akra.studio.</a>
                            </p>
                        </div>
                    </div>
            </section>
        </Layout>
    )
}

export default TheLab
